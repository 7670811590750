<template>
	<div id="view-news">
		<table class="table table-vmiddle" id="view-news-reset-table" v-if="urlHistory.length > 1">
			<tbody>
				<tr @click="navigateBack()">
					<td width="1%">
						<img src="../assets/left-angle-bracket-white.png" class="center-block item-arrow">
					</td>
					<td colspan="3">Indietro</td>
				</tr>
			</tbody>
		</table>
		<div v-if="content == null">

		</div>
		<div v-else-if="content.layout == 'lista'">
			<table class="table table-vmiddle" id="view-news-table">
				<tbody>
					<tr v-for="(record, index) in content.dati" :key="index + ' - ' + record.next_url" @click="loadUrl(record.next_url)">
						<td width="1%" v-if="record.immagine">
							<img v-lazy="'https://beta.cloud.servonet.it/' + record.immagine" class="img-responsive center-block item-icon">
						</td>
						<td :colspan="record.immagine ? 1 : 2">
							<div v-if="record.data">
								<h3>{{ record.data }}</h3>
							</div>
							{{ record.titolo }}
						</td>
						<td width="1%">
							<img src="../assets/right-angle-bracket-white.png" v-if="record.next_url" class="center-block item-arrow">
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div v-else-if="content.layout == 'pagina'">
			<div v-for="(element, index) in content.dati" :key="index">
				<div v-if="element.key == 'titolo'" class="page-element">
					<h3>{{ element.value }}</h3>
				</div>
				<div v-else-if="element.key == 'testo'" class="page-element">
					<div v-html="element.value"></div>
				</div>
				<div v-else-if="element.key == 'divider'">
					<hr>
				</div>
				<div v-else-if="element.key == 'immagine'" class="page-element">
					<img v-lazy="'https://beta.cloud.servonet.it/' + element.value" class="img-responsive center-block">
				</div>
				<div v-else-if="element.key == 'link'" class="page-element">
					<!-- TODO: Essenza Cofani al momento non implementa gli articoli -->
				</div>
				<div v-else-if="element.key == 'share'" class="page-element">
					<button type="button" class="btn-white-block" @click="shareUrl(element.value)">
						- CONDIVIDI -
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'

export default {
	name: 'News',
	data () {
		return {
			content: null,
			urlHistory: [],
		}
	},
	mounted () {
		this.loadUrl("/eventi")
	},
	methods: {
		navigateBack () {
			if (this.urlHistory.length <= 1) {
				return
			}

			let previousUrl = this.urlHistory[this.urlHistory.length - 2]

			this.loadUrl(previousUrl).then(() => {
				// After loading, the new URL will have been pushed into the array
				this.urlHistory.splice(this.urlHistory.length - 3, 2)
			})
		},
		loadUrl (partialUrl) {
			if (partialUrl.length == 0) {
				return
			}

			return axios.get("https://beta.cloud.servonet.it/json/app_essenzacofani/it" + partialUrl)
				.then(response => response.data)
				.then(response => {
					this.urlHistory.push(partialUrl)
					this.content = response
				})
		}
	}
}
</script>

<style>
img.item-icon {
	min-height: 48px;
	height: 48px;
	min-width: 48px;
	width: 48px;
	border-radius: 50%;
}
img.item-arrow {
	max-height: 30px;
}
table#view-news-table {
	cursor: pointer;
}
table#view-news-table tr:not(:first-child) td {
	border-top: solid 2px #9C9895;
}
table#view-news-table tr:last-child td {
	border-bottom: solid 2px #9C9895;
}
table#view-news-table tr:nth-child(odd) {
	background-color: #948168;
}
table#view-news-table tr:nth-child(even) {
	background-color: #8C8276;
}
table#view-news-table tr td {
	height: 60px;
	padding: 4px;
}
table#view-news-reset-table {
	cursor: pointer;
}
table#view-news-reset-table tr td {
	border-bottom: solid 2px #9C9895;
	height: 60px;
	padding: 4px;
}
h1 {
	font-weight: bold;
	text-align: center;
}
.page-element {
	padding: 8px;
}
.btn-white-block {
	cursor: pointer;
	display: block;
	width: 100%;
	height: 48px;
	color: black;
	background-color: white;
	padding: 8px;
	border: solid 2px black;
	border-radius: 4px;
	font-weight: bold;
}
</style>